import { getScrollingDocumentElement, isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { addColorClass, removeColorClass } from "../cb/colorClasses"
import { buildTooltip } from "../cb/ui/tooltip"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { roomLoaded } from "./context"
import { Component } from "./defui/component"
import { DivotPosition } from "./divot"
import { EventRouter } from "./events"
import {
    followingEvent,
    followRoom,
    getEmailNotificationSetting,
    setFollowNotificationFrequency,
    unfollowRoom,
} from "./follow"
import { addPageAction } from "./newrelic"
import { OverlayComponent } from "./overlayComponent"
import { FollowNotificationFrequency } from "./roomDossier"
import { RoomTabButton } from "./RoomTabButton"
import { EmailNotificationsModal } from "./theatermodelib/emailNotificationsModal"
import { i18n } from "./translation"
import type { IOffsets } from "./coords"
import type { IGetEmailSettings } from "./follow"
import type { INotificationPopupSettings } from "./theatermodelib/emailNotificationsModal"

const location = "FollowButton"

export class FollowUnfollowSection extends Component {
    public element = document.createElement("div")
    public followButton: FollowButton
    public unfollowButton: UnfollowButton
    public followNotificationButton: FollowNotificationButton
    private tooltip: HTMLDivElement

    // Promise object to ensure follow, unfollow, and follow frequency API calls are serialized in the same
    // order as their user actions.  This is to avoid the rare case when one API call lags behind another and
    // potentially causes the UI state to unexpectedly change.
    private followApiPromiseChain: Promise<unknown> = Promise.resolve()

    constructor(private tabBar: HTMLDivElement, roomName?: string) {
        super()

        this.followButton = new FollowButton(this, roomName)
        this.followNotificationButton = new FollowNotificationButton(this, roomName)
        this.unfollowButton = new UnfollowButton(this, roomName)
        this.element.style.display = "inline-block"
        this.element.style.cssFloat = "right"
        this.element.style.position = "relative"
        this.element.style.width = "auto"
        this.element.style.right = "1px"
        this.buildTooltip()
        tabBar.appendChild(this.tooltip)
        this.element.appendChild(this.followButton.element)
        this.element.appendChild(this.followNotificationButton.element)
        this.element.appendChild(this.unfollowButton.element)

        const scopedOriginalRoomHandler = () => {
            const ogRoomName = roomName
            roomLoaded.listen((ctx) => {
                if (ctx.dossier.room !== ogRoomName) {
                    this.hideFollowTooltip()
                }
            })
        }
        scopedOriginalRoomHandler()
    }

    private buildTooltip(): void {
        this.tooltip = buildTooltip({
            content: i18n.instantNotificationMessage,
            hasHTML: false,
            width: 220,
            divotPosition: DivotPosition.Top,
            divotLeftOrTop: "170px",
            borderWidth: 1,
        })
        this.tooltip.style.lineHeight = "22px"
        this.tooltip.style.fontSize = "14px"
        this.tooltip.style.padding = "8px 0px 8px 8px"
    }

    public showFollowTooltip(): void {
        this.tooltip.style.display = "block"
        this.repositionChildren()
    }

    public hideFollowTooltip(): void {
        this.tooltip.style.display = "none"
    }

    public showFollow(): void {
        this.followButton.element.style.display = "inline"
        this.unfollowButton.element.style.display = "none"
        this.followNotificationButton.element.style.display = "none"
    }

    public showUnfollow(followNotificationFrequency: FollowNotificationFrequency): void {
        this.followButton.element.style.display = "none"
        this.unfollowButton.element.style.display = "inline"
        this.followNotificationButton.updateFollowNotificationFrequency(followNotificationFrequency)
        this.followNotificationButton.element.style.display = "inline"
    }

    repositionChildren(): void {
        const rect = this.element.getBoundingClientRect()
        this.tooltip.style.top = `${this.tabBar.offsetHeight + 8}px`
        this.tooltip.style.left = `${rect.left + getScrollingDocumentElement().scrollLeft - this.tooltip.offsetWidth + this.element.clientWidth - 16}px`
    }

    public queueFollowApi(apiCall: () => unknown): void {
        this.followApiPromiseChain = this.followApiPromiseChain.then(apiCall)
    }
}

class UnfollowButton extends RoomTabButton {
    constructor(containerDiv: FollowUnfollowSection, roomName?: string) {
        super(roomName)
        addColorClass(this.element, "unfollowButton")
        this.textContainer.innerText = i18n.unfollow
        this.element.style.marginRight = "0px"
        this.element.style.right = ""
        this.element.style.borderRadius = "3px 0px 0px 3px"
        this.element.style.borderWidth = "1px"
        this.element.style.borderStyle = "solid"
        this.element.dataset.testid = "unfollow-button"
        this.element.onclick = () => {
            containerDiv.queueFollowApi(() => {
                return unfollowRoom(this.roomName, undefined, location)
            })
        }
        this.element.onmouseenter = () => {
            containerDiv.showFollowTooltip()
        }
        this.element.onmouseleave = () => {
            containerDiv.hideFollowTooltip()
        }
    }
}

class FollowButton extends RoomTabButton {
    constructor(containerDiv: FollowUnfollowSection, roomName?: string) {
        super(roomName)
        addColorClass(this.element, "followButton")
        this.textContainer.innerText = i18n.follow
        this.element.style.marginRight = "0px"
        this.element.style.right = ""
        this.element.dataset.testid = "follow-button"

        let settingsPopupTriggered = false
        followingEvent.listen((ctx) => {
            if (ctx.following) {
                if (!settingsPopupTriggered && ctx.followNotificationFrequency !== FollowNotificationFrequency.NONE) {
                    checkNotificationSettingsModal(this.roomName, containerDiv, this)
                    settingsPopupTriggered = true
                }
            } else {
                settingsPopupTriggered = false
            }
        })

        this.element.onclick = () => {
            containerDiv.queueFollowApi(() => {
                return followRoom(this.roomName, undefined, location)
            })
        }

        this.element.onmouseenter = () => {
            containerDiv.showFollowTooltip()
        }
        this.element.onmouseleave = () => {
            containerDiv.hideFollowTooltip()
        }
    }
}

const checkNotificationSettingsModal = (roomName: string, containerDiv: FollowUnfollowSection, followButton: FollowButton) => {
    const roomNameWithNotificationSettings = roomName
    const notificationSettingsModal = new EmailNotificationsModal()
    if (notificationSettingsModal.canRequestPermission()) {
        const isBrowserNotificationSupported = window["isBrowserNotificationSupported"]
        const isSubscribedToBrowserNotifications = window["isSubscribedToBrowserNotifications"]
        const browserOptionDisabled = isBrowserNotificationSupported === undefined ||
            isBrowserNotificationSupported() !== true ||
            isSubscribedToBrowserNotifications === undefined ||
            !isLocalStorageSupported()
        getEmailNotificationSetting().then((emailSettings: IGetEmailSettings) => {
            const notificationSettings: INotificationPopupSettings = {
                emailAddress: emailSettings.email,
                emailSubscribed: emailSettings.activated,
                emailValidated: emailSettings.validated,
            }
            const hideEmailOption = emailSettings.activated && emailSettings.validated

            roomLoaded.listen((ctx) => {
                // Close the model if the user navigates to a different room
                // than the one that was opened the model for.
                if (ctx.dossier.room !== roomNameWithNotificationSettings) {
                    notificationSettingsModal.close()
                }
            })

            const showSettingsPopup = () => {
                notificationSettingsModal.setOriginalState(notificationSettings)
                followButton.addChild(notificationSettingsModal, containerDiv.element)
                notificationSettingsModal.closed.once(() => {
                    followButton.removeChild(notificationSettingsModal)
                })
            }

            // Show modal as long as email option is not on while browser option is disabled/on
            if (!browserOptionDisabled) {
                isSubscribedToBrowserNotifications(false, () => {
                    notificationSettingsModal.showBrowserOption()
                    showSettingsPopup()
                })
                isSubscribedToBrowserNotifications(true, () => {
                    if (!hideEmailOption) {
                        showSettingsPopup()
                    }
                })
            } else if (!hideEmailOption) {
                showSettingsPopup()
            }
        }).catch((err) => {
            error(`Error fetching email settings: ${err}`)
        })
    }
}

export class FollowNotificationButton extends RoomTabButton {
    private followNotificationFrequency: FollowNotificationFrequency
    private menu?: NotificationButtonMenu

    constructor(containerDiv: FollowUnfollowSection, roomName?: string) {
        super(roomName)
        addColorClass(this.iconContainer, "notification-btn-icon")
        addColorClass(this.iconContainer, "static")
        this.iconContainer.style.width = "20px"
        this.iconContainer.style.height = "20px"
        this.iconContainer.style.display = "inline-block"
        this.iconContainer.style.backgroundRepeat = "no-repeat"
        this.updateFollowNotificationFrequency(FollowNotificationFrequency.SMART)

        this.textContainer.innerText = ""

        addColorClass(this.element, "FollowNotificationButton")
        this.element.style.padding = "0px 2px 5px 2px"
        this.element.style.marginRight = "0px"
        this.element.style.right = ""
        this.element.dataset.testid = "follow-notification-button"

        this.element.style.borderWidth = "1px 1px 1px 0"
        this.element.style.borderRadius = "0 3px 3px 0"
        this.element.style.borderStyle = "solid"

        followingEvent.listen((value) => {
            if (this.roomName === value.roomName) {
                this.updateFollowNotificationFrequency(value.followNotificationFrequency)
            }
        })

        this.element.onclick = () => {
            if (this.menu === undefined) {
                this.menu = new NotificationButtonMenu(this.followNotificationFrequency)
                this.menu.selectionChange.listen((newSetting) => {
                    addPageAction("FollowFrequencyBellClicked",
                        {
                            "previous_frequency": this.followNotificationFrequency,
                            "frequency": newSetting,
                        })
                    this.followNotificationFrequency = newSetting
                    containerDiv.queueFollowApi(() => {
                        return setFollowNotificationFrequency(this.roomName, newSetting, followingEvent)
                    })
                })
                this.menu.element.style.marginTop = "36px"
                this.menu.toggle({ top: -7, left: containerDiv.element.offsetWidth - 117 })
                this.addChild(this.menu, containerDiv.element)
            } else {
                this.menu.makeVisible()
            }
        }

        const scopedOriginalRoomHandler = () => {
            const roomNameWithFollowSection = this.roomName
            roomLoaded.listen((ctx) => {
                // Close the model if the user navigates to a different room
                // than the one that was opened the model for.
                if (ctx.dossier.room !== roomNameWithFollowSection) {
                    this.menu?.hide()
                }
            })
        }
        scopedOriginalRoomHandler()
    }

    public updateFollowNotificationFrequency(followNotificationFrequency: FollowNotificationFrequency): void {
        this.followNotificationFrequency = followNotificationFrequency
        this.iconContainer.className = ""
        addColorClass(this.iconContainer, "notification-btn-icon")
        addColorClass(this.iconContainer, "static")
        addColorClass(this.iconContainer, this.followNotificationFrequency.toString())
        if (this.menu !== undefined) {
            this.menu.setSelectedItem(followNotificationFrequency, false)
        }
    }
}

function getFollowNotificationFrequencyMenuText(followNotificationFrequency: FollowNotificationFrequency): string {
    switch (followNotificationFrequency) {
        case FollowNotificationFrequency.ALL:
            return i18n.notifyOptionAlways
        case FollowNotificationFrequency.SMART:
            return i18n.notifyOptionAuto
        case FollowNotificationFrequency.NONE:
            return i18n.never
    }
}

export class NotificationMenuItem extends Component {
    readonly icon: HTMLSpanElement
    readonly name: HTMLElement

    constructor(private onItemClick: (menuItem: FollowNotificationFrequency) => void,
        public data: FollowNotificationFrequency) {
        super()

        addColorClass(this.element, "notification-item")
        this.element.style.padding = "4px 0 4px 0"
        this.element.style.cursor = "pointer"
        this.element.style.boxSizing = "border-box"
        this.element.style.position = "relative"
        this.element.style.overflow = "visible"
        this.element.style.borderWidth = "1px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderTop = "0 none"
        this.element.style.borderBottom = "0 none"
        this.element.dataset.testid = "follow-notification-item"

        this.icon = document.createElement("span")
        addColorClass(this.icon, "notification-btn-icon")
        addColorClass(this.icon, data.toString())
        this.icon.style.padding = "0 8px 0 12px"
        this.icon.style.width = "20px"
        this.icon.style.height = "20px"
        this.icon.style.verticalAlign = "middle"
        this.icon.style.display = "inline-block"
        this.icon.style.backgroundRepeat = "no-repeat"
        this.icon.style.backgroundPosition = "center center"
        this.icon.title = getFollowNotificationFrequencyMenuText(data)

        this.element.appendChild(this.icon)

        this.name = document.createElement("span")
        this.name.style.margin = "0px"
        this.name.style.padding = "0px 4px 0px px"
        this.name.innerText = getFollowNotificationFrequencyMenuText(data)
        this.name.dataset.testid = `${data.toLocaleLowerCase()}-notification-label`
        this.element.appendChild(this.name)

        addEventListenerPoly("click", this.element, () => {
            this.onItemClick(data)
        })
    }

    select(): void {
        addColorClass(this.element, "selected")
        this.name.style.fontWeight = "bold"
        this.name.style.fontFamily = "UbuntuBold, Helvetica, Arial, sans-serif"
    }

    deselect(): void {
        removeColorClass(this.element, "selected")
        this.name.style.fontWeight = "normal"
        this.name.style.fontFamily = "UbuntuNormal, Helvetica, Arial, sans-serif"
    }
}

export class NotificationButtonMenu extends OverlayComponent {
    readonly width = 120
    protected visible = false
    readonly menuItems: NotificationMenuItem[]

    // Events
    readonly selectionChange = new EventRouter<FollowNotificationFrequency>("selectionChange")

    constructor(protected selectedItem: FollowNotificationFrequency) {
        super()

        const menuItems: NotificationMenuItem[] = []

        addColorClass(this.element, "notificationBtnMenu")
        this.element.style.padding = "0px"
        this.element.style.width = `${this.width}px`
        this.element.style.textAlign = "left"
        this.element.style.fontSize = "11px"
        this.element.style.lineHeight = "14px"
        this.element.style.borderRadius = "4px"

        this.overlay.style.zIndex = "1002"
        this.element.style.zIndex = "1003"
        this.element.style.overflow = "visible"
        this.element.style.removeProperty("height")

        const title: HTMLElement = document.createElement("div")
        addColorClass(title, "title")
        title.innerText = i18n.notifyMe
        title.style.padding = "12px"
        title.style.boxSizing = "border-box"
        title.style.position = "relative"
        title.style.overflow = "visible"
        title.style.borderWidth = "1px"
        title.style.borderStyle = "solid"
        title.style.fontFamily = "UbuntuMedium, Helvetica, Arial, sans-serif"
        title.style.fontSize = "15px"
        title.style.borderTopWidth = "1px"
        title.style.borderTopLeftRadius = "4px"
        title.style.borderTopRightRadius = "4px"
        this.element.appendChild(title)

        this.addBookend(false)
        const menuValues =
            [FollowNotificationFrequency.ALL, FollowNotificationFrequency.SMART, FollowNotificationFrequency.NONE]
        for (const value of menuValues) {
            const menuItem = new NotificationMenuItem((menuItem: FollowNotificationFrequency) => this.onItemClick(menuItem), value)
            this.addChild(menuItem)
            menuItems.push(menuItem)
        }
        this.addBookend(true)

        this.menuItems = menuItems
        this.setSelectedItem(selectedItem, false)
        this.overlayClick.listen(() => {
            this.hide()
        })
    }

    private addBookend(bottom: boolean): void {
        const bookend = document.createElement("div")
        addColorClass(bookend, "bookend")
        bookend.style.height = "4px"
        bookend.style.boxSizing = "border-box"
        bookend.style.position = "relative"
        bookend.style.borderWidth = "1px"
        bookend.style.borderStyle = "solid"
        bookend.style.borderTop = "0 none"
        if (bottom) {
            bookend.style.borderBottomLeftRadius = "4px"
            bookend.style.borderBottomRightRadius = "4px"
            bookend.style.borderBottomWidth = "1px"
            bookend.style.borderBottomStyle = "solid"
            bookend.style.paddingTop = "4px"
        } else {
            bookend.style.borderBottom = "0 none"
        }
        this.element.appendChild(bookend)
    }

    isVisible(): boolean {
        return this.visible
    }

    show(offsets: IOffsets): void {
        if (this.isVisible()) {
            return
        }
        this.element.style.top = `${offsets.top}px`
        this.element.style.left = `${offsets.left}px`
        this.makeVisible()
        this.repositionChildrenRecursive()
    }

    makeVisible(): void {
        if (this.isVisible()) {
            return
        }
        this.element.style.display = "inline-block"
        this.showOverlay()
        this.visible = true
    }

    hide(): void {
        if (!this.isVisible()) {
            return
        }
        super.hide()
        this.visible = false
    }

    toggle(offsets: IOffsets): void {
        if (this.isVisible()) {
            this.hide()
        } else {
            this.show(offsets)
        }
    }

    public setSelectedItem(value: FollowNotificationFrequency, fireEvent: boolean): void {
        if (value !== this.selectedItem) {
            this.selectedItem = value
            if (fireEvent) {
                this.selectionChange.fire(value)
            }
        }
        for (const item of this.menuItems) {
            if (item.data === value) {
                item.select()
            } else {
                item.deselect()
            }
        }
    }

    public onItemClick(menuItem: FollowNotificationFrequency): void {
        this.hide()
        this.setSelectedItem(menuItem, true)
    }
}
