import { t } from "@lingui/macro"
import { getScrollingDocumentElement } from "@multimediallc/web-utils/modernizr"
import { addColorClass, colorClass } from "../../../cb/colorClasses"
import { ReactComponentRegistry } from "../../../cb/components/ReactRegistry"
import { getValidCountryData } from "../../../cb/components/roomlist/countryFlagUtils"
import { DjangoFormParser } from "../../../cb/ui/djangoForm"
import { CustomField } from "../../../cb/ui/fields"
import { addEventListenerPoly } from "../../addEventListenerPolyfill"
import { getCb, normalizeResource } from "../../api"
import { applyStyles } from "../../DOMutils"
import { i18n } from "../../translation"
import { dom } from "../../tsxrender/dom"
import { BaseRoomTab } from "./baseRoomTab"
import { BroadcasterTab, broadcasterTabSwitched } from "./broadcastRoomTabs"
import { NewIcon } from "./newIcon"
import { SettingsTabForm } from "./settingsTabForm"
import type { DjangoForm, IDjangoFormData } from "../../../cb/ui/djangoForm"

export class SettingsTab extends BaseRoomTab<IDjangoFormData> {
    private form: DjangoForm
    private saveNotice: HTMLDivElement

    constructor(private country?: string) {
        super()

        this.setResourceUrl("api/ts/accounts/editsettings/")
    }

    protected fetchData(path: string): Promise<XMLHttpRequest> {
        return getCb(path)
    }

    protected parseData(rawData: string): IDjangoFormData {
        return DjangoFormParser.parseData(rawData)
    }

    protected createContent(data: IDjangoFormData): void {
        this.form = new SettingsTabForm(data, "api/ts/accounts/editsettings/")
        this.form.element.dataset.testid = "settings-tab-form"
        this.form.element.style.position = "relative"
        this.addChild(this.form)
        this.form.prependFieldset(i18n.security)
        this.form.addFieldToFieldset(new CustomField({
            name: "security",
            customElement: this.createSecuritySection(),
        }), i18n.security)

        this.form.addFieldset(i18n.statistics)
        this.form.addFieldToFieldset(new CustomField({
            name: "statistics",
            customElement: this.createStatisticsSection(),
        }), i18n.statistics)

        let eventsApiQRCodeImgData = ""
        if ("events_api_qr_img_data" in data.extra) {
            eventsApiQRCodeImgData = data.extra["events_api_qr_img_data"] as string
        }
        this.updateAuthSection(eventsApiQRCodeImgData)
        this.form.setSubmitText(i18n.updateSettings)
        this.form.getSubmitButton().dataset.testid = "update-settings-button"

        this.updateOptInFlagDisplaySection()

        this.createSaveSettingsNotice()
        broadcasterTabSwitched.listen((tabBeforeSwitch) => {
            if (tabBeforeSwitch === BroadcasterTab.SETTINGS) {
                // Try to submit any changes made to the settings before switching tab or closing the browser window.
                this.form.triggerExternalSubmit()
            }
        })

        this.createAutoRefillSection()
    }

    private createSaveSettingsNotice(): void {
        this.saveNotice = document.createElement("div")
        this.saveNotice.innerText = i18n.settingsSaved
        applyStyles(this.saveNotice, {
            position: "absolute",
            top: "10px",
            right: "40px",
            fontFamily: "UbuntuRegular, Helvetica, Arial, sans-serif",
            color: "#000000",
            backgroundColor: "#d8deea",
            border: "1px solid #acacac",
            borderRadius: "4px",
            padding: "8px 12px",
            visibility: "hidden",
        })
        this.saveNotice.dataset.testid = "settings-saved-notice"
        this.form.element.appendChild(this.saveNotice)

        addEventListenerPoly("scroll", document, () => {
            this.setSaveNoticeScroll()
        })

        let saveNoticeTimeout: number
        (this.form as SettingsTabForm).settingsSavedRequest.listen((isSuccess: boolean) => {
            this.saveNotice.innerText = isSuccess ? i18n.settingsSaved : i18n.settingsNotSaved
            clearTimeout(saveNoticeTimeout)
            this.saveNotice.style.visibility = "visible"
            this.setSaveNoticeScroll()
            saveNoticeTimeout = window.setTimeout(() => {
                this.saveNotice.style.visibility = "hidden"
            }, 3000)
        })
    }

    private setSaveNoticeScroll(): void {
        if (getScrollingDocumentElement().scrollTop > this.element.offsetTop - 15) {
            this.saveNotice.style.top = `${getScrollingDocumentElement().scrollTop - this.element.offsetTop + 20}px`
        } else {
            this.saveNotice.style.top = "10px"
        }
    }

    private createSecuritySection(): HTMLElement {
        const container = document.createElement("div")
        const linkContainer1 = this.createSubNav()
        const linkContainer2 = this.createSubNav()
        const changePasswordLink = document.createElement("a")
        const securityCenterLink = document.createElement("a")

        container.appendChild(linkContainer1)
        container.appendChild(linkContainer2)
        linkContainer1.appendChild(changePasswordLink)
        linkContainer2.appendChild(securityCenterLink)

        addColorClass(changePasswordLink, colorClass.hrefColor)
        changePasswordLink.innerText = i18n.updateYourPassword
        changePasswordLink.href = normalizeResource("/auth/password_change/")
        changePasswordLink.target = "_blank"
        changePasswordLink.dataset.testid = "change-password-link"

        addColorClass(securityCenterLink, colorClass.hrefColor)
        securityCenterLink.innerText = i18n.viewSecurityCenter
        securityCenterLink.href = normalizeResource("/security/")
        securityCenterLink.target = "_blank"
        securityCenterLink.dataset.testid = "security-center-link"

        return container
    }

    private createStatisticsSection(): HTMLElement {
        const container = document.createElement("div")
        const linkContainer = this.createSubNav()
        const authorizeStatsLink = document.createElement("a")

        container.appendChild(linkContainer)
        linkContainer.appendChild(authorizeStatsLink)

        addColorClass(authorizeStatsLink, colorClass.hrefColor)
        authorizeStatsLink.innerText = i18n.authorizeThirdPartyStats
        authorizeStatsLink.href = normalizeResource("/statsapi/authtoken/")
        authorizeStatsLink.target = "_blank"
        authorizeStatsLink.dataset.testid = "authorize-stats-link"

        return container
    }

    private updateAuthSection(eventsApiQRCodeImgData: string): void {
        const fieldSetName = i18n.eventsApiSettingsHeader
        const authForm = this.form.getFieldset(fieldSetName)
        if (authForm === undefined) {
            return
        }

        const authSectionInfo = document.createElement("span")
        let authSectionInfoHtml = i18n.authSectionInfo(this.form.getField("events_url")?.getValue() as string)
        if (eventsApiQRCodeImgData.length > 0) {
            authSectionInfoHtml = i18n.authSectionInfo2(this.form.getField("events_url")?.getValue() as string)
        }
        authSectionInfo.innerHTML = authSectionInfoHtml // eslint-disable-line @multimediallc/no-inner-html
        authForm?.prepend(authSectionInfo)
        if (eventsApiQRCodeImgData.length > 0) {
            const imgEventsApiQRCode = document.createElement("img")
            imgEventsApiQRCode.classList.add("responsiveQrCode")
            imgEventsApiQRCode.src = `data:image/svg+xml;base64,${eventsApiQRCodeImgData}`
            imgEventsApiQRCode.style.float = "left"
            imgEventsApiQRCode.style.marginRight = "2rem"
            imgEventsApiQRCode.style.width = "111px" // Dimensions should be multiple of 37 to render sharply
            imgEventsApiQRCode.style.height = "111px"
            authForm?.prepend(imgEventsApiQRCode)
        }
    }

    private createAutoRefillSection(): void {
        const autoRefillForm = this.form.getFieldset(t`Token Auto Refill`)

        if (autoRefillForm === undefined) {
            return
        }

        this.applyAutoRefillStyles()
        const autoRefillSectionHtml = t`
            When your account balance falls below
            <span class="auto-refill-section-strong">10% of the refill amount</span>,
            it will be automatically recharged using your saved payment method.
        `


        const autoRefillSectionInfo = document.createElement("span")
        autoRefillSectionInfo.innerHTML = autoRefillSectionHtml // eslint-disable-line @multimediallc/no-inner-html
        autoRefillForm.parentElement?.before(autoRefillSectionInfo)
    }

    private applyAutoRefillStyles(): void {
        const autoRefillForm = this.form.getFieldset(t`Token Auto Refill`)
        const tableElement = this.form.element.querySelector("table")
        const settingsTab = this.form.element.parentElement

        if (autoRefillForm === undefined || tableElement === null || settingsTab === null) {
            return
        }

        applyStyles(this.form, { overflow: "visible" })
        applyStyles(settingsTab, { overflow: "visible" })
        applyStyles(tableElement, { width: "100%" })

        const autoRefillFieldset = autoRefillForm.closest("fieldset")
        const autoRefillLegendEl = autoRefillFieldset?.querySelector(".label") as HTMLElement | null

        if (!autoRefillLegendEl || !autoRefillFieldset) {
            return
        }

        const newSignSpan =
            <span className="auto-refill-newSign">
                <NewIcon/>
                <span className="auto-refill-newSign-text">{i18n.new}</span>
            </span>

        autoRefillFieldset.classList.add("auto-refill-fieldset")
        autoRefillLegendEl.classList.add("auto-refill-legend")
        autoRefillLegendEl.appendChild(newSignSpan)

        this.animateIfFromAutorefillLink(autoRefillFieldset)
    }

    private animateIfFromAutorefillLink(autoRefillFieldset: HTMLElement): void {
        const searchParams = new URLSearchParams(window.location.search)
        const isFromAutorefillLink = searchParams.get("from_autorefill_link")
        if (isFromAutorefillLink === "1") {
            autoRefillFieldset.classList.add("pulsate-background")
            window.setTimeout(() => {
                autoRefillFieldset.classList.remove("pulsate-background")
            }, 3000)
        }
    }

    private createSubNav(): HTMLDivElement {
        const subNav = document.createElement("div")
        subNav.style.marginBottom = "15px"
        return subNav
    }

    public getForm(): DjangoForm {
        return this.form
    }

    private updateOptInFlagDisplaySection(): void {
        const optInFlagDisplaySelect = this.form.getField("opt_in_flag_display")

        if (optInFlagDisplaySelect !== undefined) {
            // TODO: https://multimediallc.leankit.com/card/30502080673196 Consider refactoring how labels are handled for Settings Form
            // We want the container to still maintain spacing in the table
            const labelContainer = optInFlagDisplaySelect.getLabelContainer()
            labelContainer.style.display = ""
            labelContainer.style.visibility = "hidden"

            const country = this.country?.toLowerCase()
            const validatedCountryData = getValidCountryData(country)

            optInFlagDisplaySelect.getWidgetContainer().classList.add("opt-in-flag-display")
            const flagTextContainer = document.createElement("div")
            const OptInFlagDisplayHelpText = ReactComponentRegistry.get("OptInFlagDisplayHelpText")
            new OptInFlagDisplayHelpText({
                "countryCode": validatedCountryData.flagCode,
                "countryName": validatedCountryData.flagName,
            }, flagTextContainer)

            const helpText = optInFlagDisplaySelect.getHelpTextElement()
            helpText.style.display = "flex"
            helpText.appendChild(flagTextContainer)
            helpText.appendChild(optInFlagDisplaySelect.createUnsavedHeptextSpan())
        }
    }
}
