import { dom } from "../../tsxrender/dom"

export const NewIcon = (): HTMLElement => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <g clip-path="url(#clip0_10707_246981)">
                <path d="M9.5 0.5L8.87 1.875L7.5 2.5L8.87 3.13L9.5 4.5L10.125 3.13L11.5 2.5L10.125 1.875M4.5 2L3.25 4.75L0.5 6L3.25 7.25L4.5 10L5.75 7.25L8.5 6L5.75 4.75M9.5 7.5L8.87 8.87L7.5 9.5L8.87 10.125L9.5 11.5L10.125 10.125L11.5 9.5L10.125 8.87" fill="#288A09"/>
            </g>
            <defs>
                <clipPath id="clip0_10707_246981">
                    <rect width="12" height="12" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
