import { ArgJSONMap } from "@multimediallc/web-utils"
import { webRTCBroadcastStartStop } from "../../../cb/broadcastStatus"
import { addColorClass } from "../../../cb/colorClasses"
import { GameSelection } from "../../../cb/components/games/gameSelection"
import { currentSiteSettings } from "../../../cb/siteSettings"
import { normalizeResource } from "../../api"
import { applyStyles } from "../../DOMutils"
import { addPageAction } from "../../newrelic"
import { isProfilePage } from "../../profilelib/profileRoot"
import { i18n } from "../../translation"
import { largePopupLinkFeatures, safeWindowOpen } from "../../windowUtils"
import { BaseRoomTab } from "./baseRoomTab"
import type { GameSelectionData } from "../../../cb/components/games/gameSelection"
import type { EventRouter } from "../../events"

interface IGamesData {
    "game_selection": GameSelectionData,
    "previous_game_selection": GameSelectionData,
}

declare global {
    interface Window {
        webRTCStartStop: EventRouter<boolean>
        selectGameChange: EventRouter<GameSelectionData>
    }
}

export class GamesTab extends BaseRoomTab<IGamesData> {
    constructor() {
        super()
        addColorClass(this.element, "GamesTab")

        this.setResourceUrl("api/ts/chat/games/")
    }

    protected createContent(data: IGamesData): void {
        const h1 = (text: string): HTMLHeadingElement => {
            const e = document.createElement("h1")
            applyStyles(e, {
                fontSize: "1.75em",
                fontWeight: "bolder",
            })

            e.innerText = text
            e.dataset.testid = "games-header"
            return e
        }

        const p = (html: string): HTMLParagraphElement => {
            const e = document.createElement("p")
            e.innerHTML = html.replace(/\s{2,}/gm, " ") // eslint-disable-line @multimediallc/no-inner-html
            e.style.width = "90%"
            return e
        }

        /**
         * @returns Box section to deliniate Apps & Bots and Games
         */
        const tabSection = (): HTMLDivElement => {
            const e = document.createElement("div")
            addColorClass(e, "tabSection")
            applyStyles(e, {
                width: "620px",
                margin: "0.5em",
                padding: "0.25em 1.25em",
                borderRadius: "4px",
            })
            return e
        }

        /**
         * @returns Table to contain the section items
         */
        const table = (): HTMLTableElement => {
            const e = document.createElement("table")
            applyStyles(e, {
                borderCollapse: "collapse",
                marginBottom: "1em",
                width: "620px",
            })

            return e
        }

        const GameSelectionTable = (selectedGame: GameSelectionData, previousGame: GameSelectionData): HTMLTableElement => {
            const gamesSectionTable = table()
            const row = document.createElement("tr")
            const legendContainer = document.createElement("td")
            const legend = document.createElement("legend")
            const selected = document.createElement("td")
            const chooseGameContainer = document.createElement("td")

            addColorClass(legend, "label")
            selected.style.color = "grey"
            selected.style.fontStyle = "italic"
            selected.dataset.testid = "selected-game"
            if (selectedGame === undefined) {
                selected.innerText = i18n.noneSelected
            } else {
                selected.innerText = selectedGame.name
            }

            const chooseLinks = document.createElement("span")

            const chooseLink = document.createElement("a")
            chooseLink.href = "#"
            if (selectedGame === undefined) {
                chooseLink.innerText = i18n.chooseAGame
                chooseLink.dataset.testid = "choose-game-link"
                chooseLink.href = normalizeResource("/games/search_games/")
                chooseLink.onclick = (event: Event) => {
                    event.preventDefault()
                    addPageAction("SelectGameClicked")
                    // Pass event router for webrtc start/stop event
                    // to window for window child to access
                    window["webRTCStartStop"] = webRTCBroadcastStartStop
                    window["selectGameChange"] = GameSelection.selectionChange
                    safeWindowOpen(chooseLink.href, "_blank", largePopupLinkFeatures)
                }
                chooseLinks.appendChild(chooseLink)

                if (previousGame !== undefined) {
                    const or = document.createElement("span")
                    addColorClass(or, "orText")
                    or.innerText = ` ${i18n.orSimple} `
                    chooseLinks.appendChild(or)

                    const previousLink = document.createElement("a")
                    previousLink.href = "#"

                    previousLink.innerText = `Play "${previousGame.name}" again`
                    previousLink.onclick = (event: Event) => {
                        event.preventDefault()
                        GameSelection.selectGame(previousGame.uid).then((success: boolean) => {
                            if (!success) { // immediately hide, since the server has already removed the selection
                                chooseLinks.removeChild(or)
                                chooseLinks.removeChild(previousLink)
                            }
                        }).catch(() => {})
                    }
                    chooseLinks.appendChild(previousLink)
                }
            } else {
                chooseLink.innerText = i18n.endGame
                addColorClass(chooseLink, "deactivate")
                chooseLink.onclick = (event: Event) => {
                    event.preventDefault()
                    GameSelection.deselectGame()  // eslint-disable-line @typescript-eslint/no-floating-promises
                }
                chooseLinks.appendChild(chooseLink)
            }
            chooseGameContainer.appendChild(chooseLinks)

            legendContainer.style.width = "150px"
            chooseGameContainer.style.textAlign = "right"

            gamesSectionTable.appendChild(row)
            legendContainer.appendChild(legend)
            legend.innerText = `${i18n.activeGame}:`
            legend.dataset.testid = "active-game-label"
            row.appendChild(legendContainer)
            row.appendChild(selected)
            row.appendChild(chooseGameContainer)

            return gamesSectionTable
        }

        const container = document.createElement("div")

        if (!isProfilePage()) {
            const gamesSection = tabSection()
            gamesSection.appendChild(h1(i18n.games))

            const gamesText = gamesSection.appendChild(p(i18n.gamesText(currentSiteSettings.sanitizedSiteName, currentSiteSettings.cbGamesUrl)))
            gamesText.dataset.testid = "games-text"

            let gamesSelectionTable = GameSelectionTable(data["game_selection"], data["previous_game_selection"])
            gamesSection.appendChild(gamesSelectionTable)

            GameSelection.selectionChange.listen((game: GameSelectionData) => {
                const newRoot = GameSelectionTable(game, data["previous_game_selection"])
                gamesSection.replaceChild(newRoot, gamesSelectionTable)
                gamesSelectionTable = newRoot
                if (game !== undefined) {
                    data["previous_game_selection"] = game // set it immediately to prevent round trip
                }
            }, false)

            container.appendChild(gamesSection)
        }

        this.element.appendChild(container)
    }

    protected parseData(rawData: string): IGamesData {
        const dataMap = new ArgJSONMap(rawData)
        const parsedData = {
            "game_selection": this.parseGameSelection(dataMap.getObjectStringOrUndefined("game_selection")),
            "previous_game_selection": this.parseGameSelection(dataMap.getObjectStringOrUndefined("previous_game_selection")),
        }
        dataMap.logUnusedDebugging("GamesTab")
        GameSelection.selectionChange.fire(parsedData["game_selection"])
        return parsedData
    }

    private parseGameSelection(rawData: string | undefined): GameSelectionData {
        if (rawData === undefined) {
            return undefined
        }
        const dataMap = new ArgJSONMap(rawData)
        return {
            description: dataMap.getString("description"),
            gameUrl: dataMap.getString("game_url"),
            image: dataMap.getString("image_64x64"),
            name: dataMap.getString("name"),
            uid: dataMap.getString("uid"),
            viewers: dataMap.getNumberOrUndefined("viewers"),
        }
    }
}
