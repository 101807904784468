import { t } from "@lingui/macro"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getSlottedAppList } from "../../../cb/api/asp"
import { addColorClass, colorClass } from "../../../cb/colorClasses"
import { AspPanel } from "../../../cb/components/asp/aspPanel"
import { ReactComponentRegistry } from "../../../cb/components/ReactRegistry"
import { pageContext } from "../../../cb/interfaces/context"
import { addEventListenerPoly } from "../../addEventListenerPolyfill"
import { modalAlert } from "../../alerts"
import { normalizeResource, postCb } from "../../api"
import { profileLoaded, roomLoaded } from "../../context"
import { featureFlagIsActive } from "../../featureFlag"
import { colorModeChangeRequest } from "../../fullvideolib/userActionEvents"
import { allSettledPromises } from "../../promiseUtils"
import { i18n } from "../../translation"
import { safeWindowOpen, toolbarPopupLinkFeatures } from "../../windowUtils"
import { BaseRoomTab } from "./baseRoomTab"
import type { IAspPanelProps } from "../../../cb/components/asp/aspPanel"
import type { ReactComponent } from "../../../cb/components/ReactRegistry"
import type { IAppListResponseData } from "../../../cb/interfaces/asp"
import type { IBroadcastContext } from "../../../entrypoints/broadcast"
import type { IRoomDossier } from "../../roomDossier"

interface IPreviousApp {
    app_slug: string
    app_version: string
    app_name: string
}

interface IAppsBotsData {
    slot0: string | undefined
    slot1: string | undefined
    slot2: string | undefined
    slot3: string | undefined
    slot4: string | undefined
    slot5: string | undefined
    previous_app_0: IPreviousApp | undefined
    previous_app_1: IPreviousApp | undefined
    previous_app_2: IPreviousApp | undefined
    previous_app_3: IPreviousApp | undefined
    previous_app_4: IPreviousApp | undefined
    previous_app_5: IPreviousApp | undefined
}

const APP_DIRECTORY_V3_ONBOARDING_KEY = "app-directory-v3-onboarding"

export class AppsTab extends BaseRoomTab<IAppsBotsData> {
    aspPanel: AspPanel

    constructor(private roomDossier: IRoomDossier) {
        super()
        addColorClass(this.element, "appsTab")

        profileLoaded.listen(() => {
            if (this.showing) {
                this.load()
            }
        })

        roomLoaded.listen((context) => {
            context.chatConnection.event.appTabRefresh.listen(() => {
                if (this.showing) {
                    this.load()
                }
                if (this.aspPanel !== undefined) {
                    this.aspPanel.notifyDirectoryPopUpsRefresh()
                }
            })
        })
    }

    protected createContent(parsedData: IAppsBotsData): void {}

    private createCbappsTable(data: IAppsBotsData): HTMLTableElement {
        const table = document.createElement("table")
        addColorClass(table, "table")
        table.dataset.testid = "cb-panel"
        table.style.width = "850px"
        table.style.borderWidth = "1px"
        table.style.borderStyle = "solid"
        table.style.borderRadius = "5px 5px 0 0"
        table.style.borderSpacing = "0"

        table.appendChild(this.createCbappsTableHeader())
        table.appendChild(this.createCbappsTableFooter())

        const tbody = document.createElement("tbody")
        table.appendChild(tbody)

        const tableLegends = [
            `${i18n.activeApp}:`,
            `${i18n.bot1}:`,
            `${i18n.bot2}:`,
            `${i18n.bot3}:`,
            `${i18n.bot4}:`,
            `${i18n.bot5}:`,
        ]
        for (let slot = 0; slot < tableLegends.length; slot += 1) {
            const appName = data[`slot${slot}` as keyof IAppsBotsData] as string
            const previousApp = data[`previous_app_${slot}` as keyof IAppsBotsData] as IPreviousApp
            const isApp = slot === 0

            const row = document.createElement("tr")
            addColorClass(row, "row")

            const legendContainer = document.createElement("td")
            const legend = document.createElement("legend")
            const selected = document.createElement("td")
            const chooseAppOrBotContainer = document.createElement("td")

            addColorClass(legend, "label")
            legend.dataset.testid = `app-label-${slot}`
            legend.innerText = tableLegends[slot]
            legend.style.padding = "4px 8px"

            selected.dataset.testid = `app-name-${slot}`
            if (appName === undefined) {
                addColorClass(selected, "noneSelected")
                selected.innerText = i18n.noneSelected
                selected.style.fontStyle = "italic"
            } else {
                selected.innerText = appName
            }
            const chooseLink = document.createElement("a")
            chooseLink.dataset.testid = `choose-app-btn-${slot}`
            addColorClass(chooseLink, colorClass.hrefColor)

            chooseAppOrBotContainer.appendChild(chooseLink)
            chooseAppOrBotContainer.style.paddingRight = "8px"
            if (appName === undefined) {
                chooseLink.href = normalizeResource(
                    isApp ? "/apps/" : `/apps/bot/${slot}/`,
                )
                chooseLink.onclick = (event: Event) => {
                    event.preventDefault()
                    safeWindowOpen(
                        chooseLink.href,
                        "_blank",
                        toolbarPopupLinkFeatures,
                    )
                }
                chooseLink.innerText = isApp
                    ? i18n.chooseAnApp
                    : i18n.chooseABot
            } else {
                addColorClass(chooseLink, "deactivate")
                chooseLink.name = `/app/stop/${slot}`
                chooseLink.innerText = i18n.deactivateText
                chooseLink.href = "#"
                chooseLink.onclick = (event: Event) => {
                    event.preventDefault()
                    postCb(`app/stop/${slot}/`, {})
                        .then(() => {
                            this.load()
                        })
                        .catch(() => {
                            modalAlert("Error deactivating app")
                        })
                }
            }
            if (previousApp !== undefined) {
                const or = document.createElement("span")
                const restart = document.createElement("a")

                addColorClass(or, "orText")
                or.innerText = ` ${i18n.orSimple} `

                addColorClass(restart, colorClass.hrefColor)
                restart.innerText = `${i18n.restart} "${previousApp.app_name}"`
                restart.href = normalizeResource(
                    `/app/startup/${previousApp.app_slug}/?version=${previousApp.app_version}&slot=${slot}`,
                )
                restart.onclick = (event: Event) => {
                    event.preventDefault()
                    safeWindowOpen(
                        restart.href,
                        "_blank",
                        toolbarPopupLinkFeatures,
                    )
                }

                chooseAppOrBotContainer.appendChild(or)
                chooseAppOrBotContainer.appendChild(restart)
            }

            legendContainer.style.width = "150px"
            chooseAppOrBotContainer.style.textAlign = "right"

            tbody.appendChild(row)
            legendContainer.appendChild(legend)
            row.appendChild(legendContainer)
            row.appendChild(selected)
            row.appendChild(chooseAppOrBotContainer)
        }

        return table
    }

    private createCbappsTableHeader(): HTMLTableSectionElement {
        const thead = document.createElement("thead")
        const headerRow = document.createElement("tr")
        const header = document.createElement("th")
        header.dataset.testid = "cbapps-header"
        header.colSpan = 3
        header.innerText = `v1 ${i18n.appsAndBots}`
        header.style.textAlign = "left"
        header.style.fontSize = "13px"
        header.style.borderRadius = "5px 5px 0 0"
        header.style.padding = "7px 10px"
        header.style.fontWeight = "normal"
        addColorClass(header, "header")

        headerRow.appendChild(header)
        thead.appendChild(headerRow)

        return thead
    }

    private createCbappsTableFooter(): HTMLTableSectionElement {
        const tfoot = document.createElement("tfoot")
        const footerRow = document.createElement("tr")
        const footer = document.createElement("td")
        footer.dataset.testid = "cbapps-footer"
        footer.innerHTML = i18n.v1AppsAndBotsFooterText() // eslint-disable-line @multimediallc/no-inner-html
        footer.colSpan = 3
        footer.style.padding = "7px 10px"
        footer.style.fontSize = "12px"
        footerRow.appendChild(footer)
        tfoot.appendChild(footerRow)

        return tfoot
    }

    private loadContent(
        cbappsData?: IAppsBotsData,
        aspData?: IAppListResponseData,
    ): void {
        const container = document.createElement("div")
        const error = document.createElement("p")
        error.style.color = "red"
        error.style.fontSize = "18px"

        this.element.appendChild(container)
        const description = document.createElement("p")
        description.style.width = "580px"
        description.style.fontSize = "13px"
        description.style["-webkit-font-smoothing"] = "antialiased"
        description.innerHTML = i18n.appsTabDescription // eslint-disable-line @multimediallc/no-inner-html
        description.dataset.test = "apps-description"
        container.appendChild(description)

        if (aspData !== undefined) {
            let popUpMap = {}
            if (this.aspPanel !== undefined) {
                popUpMap = this.aspPanel.appDirectoryPopUps
            }
            const aspProps: IAspPanelProps = {
                authUrl: this.roomDossier.aspAuthUrl,
                savedApps: [],
                appDirectoryPopUps: popUpMap,
            }
            aspProps.savedApps = aspData.items
            this.aspPanel = new AspPanel(aspProps)
            container.appendChild(this.aspPanel.render())
        } else {
            error.textContent = i18n.appsLoadingErrorV2
            container.appendChild(error)
        }

        container.appendChild(document.createElement("br"))

        if (cbappsData !== undefined) {
            container.appendChild(this.createCbappsTable(cbappsData))
        } else {
            error.textContent = i18n.appsLoadingError
            container.appendChild(error)
        }
    }

    private fetchCbappsData(): Promise<IAppsBotsData> {
        const path = "api/ts/chat/app-and-bots/"
        return this.fetchData(path).then((xhr) => {
            return new Promise<IAppsBotsData>((resolve, reject) => {
                try {
                    resolve(this.parseData(xhr.responseText))
                } catch (err) {
                    error("Error parsing response from resource", {
                        path: path,
                        reason: err.toString(),
                    })
                    reject(err)
                }
            })
        })
    }

    protected load(): void {
        this.showingError = false
        Promise.all(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            allSettledPromises<any>([
                this.fetchCbappsData(),
                getSlottedAppList(),
            ]),
        )
            .then((results) => {
                this.clearContent()
                if (results.every((p) => p.status === "rejected")) {
                    this.showErrorMessage()
                } else {
                    this.loadContent(...results.map((p) => p.value))
                }
            })
            .catch(() => {})
    }

    protected parseData(rawData: string): IAppsBotsData {
        const dataMap = new ArgJSONMap(rawData)
        const parsedData = {
            slot0: dataMap.getStringOrUndefined("slot0"),
            slot1: dataMap.getStringOrUndefined("slot1"),
            slot2: dataMap.getStringOrUndefined("slot2"),
            slot3: dataMap.getStringOrUndefined("slot3"),
            slot4: dataMap.getStringOrUndefined("slot4"),
            slot5: dataMap.getStringOrUndefined("slot5"),
            previous_app_0: this.parsePreviousApp(
                dataMap.getObjectStringOrUndefined("previous_app_0"),
            ),
            previous_app_1: this.parsePreviousApp(
                dataMap.getObjectStringOrUndefined("previous_app_1"),
            ),
            previous_app_2: this.parsePreviousApp(
                dataMap.getObjectStringOrUndefined("previous_app_2"),
            ),
            previous_app_3: this.parsePreviousApp(
                dataMap.getObjectStringOrUndefined("previous_app_3"),
            ),
            previous_app_4: this.parsePreviousApp(
                dataMap.getObjectStringOrUndefined("previous_app_4"),
            ),
            previous_app_5: this.parsePreviousApp(
                dataMap.getObjectStringOrUndefined("previous_app_5"),
            ),
        }
        dataMap.logUnusedDebugging("AppsAndBotsTab")
        return parsedData
    }

    private parsePreviousApp(
        rawData: string | undefined,
    ): IPreviousApp | undefined {
        if (rawData === undefined) {
            return undefined
        }
        const dataMap = new ArgJSONMap(rawData)
        return {
            app_slug: dataMap.getString("app_slug"),
            app_version: dataMap.getString("app_version"),
            app_name: dataMap.getString("app_name"),
        }
    }
}

export class AppsTab2 extends BaseRoomTab<object> {
    private container: HTMLDivElement | undefined
    private confirmationModal: ReactComponent | undefined
    private onboardingModal: ReactComponent | undefined
    private frame: HTMLIFrameElement
    private authToken: string
    private appDirectoryV3Url: string
    private frameWidth = "1180px"
    private frameHeight = "824px"
    private popOutWindow: Window | undefined

    constructor(private roomDossier: IRoomDossier) {
        super()
        addColorClass(this.element, "appsTab")
        this.appDirectoryV3Url = (pageContext.current as IBroadcastContext).appDirectoryV3Url
        this.authToken = (pageContext.current as IBroadcastContext).appDirectoryAuthToken
        this.frame = document.createElement("iframe")
        this.element.style.overflow = "visible"
        this.setupMessageListeners()
        if (featureFlagIsActive("AppDirV3DashboardLayout")) {
            this.setupIframeResize()
        }
        profileLoaded.listen(() => {
            if (this.showing) {
                this.load()
            }
        })

        roomLoaded.listen((context) => {
            context.chatConnection.event.appTabRefresh.listen(() => {
                this.frame.contentWindow?.postMessage("asp-tab-refresh", this.appDirectoryV3Url)
                if (this.popOutWindow && !this.popOutWindow?.closed) {
                    this.popOutWindow?.postMessage("asp-tab-refresh", "*")
                }
            })
        })
        colorModeChangeRequest.listen((mode) => {
            this.frame.contentWindow?.postMessage({ "func": "colorModeChange", "mode": mode }, this.appDirectoryV3Url)
            if (this.popOutWindow && !this.popOutWindow?.closed) {
                this.popOutWindow?.postMessage({ "func": "colorModeChange", "mode": mode }, "*")
            }
        })
    }

    protected createContent(parsedData: object): void {}

    protected parseData(rawData: string): object {
        return {}
    }

    protected load(): void {
        this.showingError = false
        this.loadContent()
    }

    private setupMessageListeners(): void {
        addEventListenerPoly("message", window, (ev) => {
            if (ev.origin.toLowerCase() !== this.appDirectoryV3Url.toLowerCase()) {
                return
            }
            if (ev.data["func"] === "getToken") {
                this.frame.contentWindow?.postMessage({ "func": "authToken", "token": this.authToken }, this.appDirectoryV3Url)
            }
        })
    }

    private setupIframeResize(): void {
        addEventListenerPoly("message", window, (ev) => {
            if (ev.origin.toLowerCase() !== this.appDirectoryV3Url.toLowerCase()) {
                return
            }
            if (ev.data["func"] === "resizeIframe") {
                const height = ev.data["height"]
                if (typeof height === "number") {
                    this.frame.style.height = `${height}px`
                } else {
                    error("Height value is invalid:", height)
                }
            }
        })
    }

    private loadContent(): void {
        if (this.container === undefined) {
            this.container = document.createElement("div")
            this.container.id = "app-directory-v3-container"
            const tabHeader = document.createElement("div")
            tabHeader.style.display = "flex"
            tabHeader.style.flexDirection = "row"
            tabHeader.style.justifyContent = "space-between"
            tabHeader.style.width = this.frameWidth
            tabHeader.style.alignItems = "center"
            tabHeader.appendChild(this.createContentTitle())
            tabHeader.appendChild(this.createV3Toggle())
            this.container.appendChild(tabHeader)

            if (featureFlagIsActive("AppDirectoryV3OptIn")) {
                tabHeader.style.maxWidth = "850px"
                tabHeader.style.marginBottom = "-35px"
                tabHeader.style.marginLeft = "22px"
                const legacyTab = new AppsTab(this.roomDossier)
                this.container.appendChild(legacyTab.element)
                legacyTab.show()
            } else {
                this.setupIframe()
                tabHeader.style.maxWidth = "100%"
                if(localStorage.getItem(APP_DIRECTORY_V3_ONBOARDING_KEY) !== "true") {
                    this.container.appendChild(this.createV3OnboardingPopup())
                }
                this.container.appendChild(this.frame)
            }
            this.element.appendChild(this.container)
        }
    }

    private setupIframe(): void {
        const iframeUrl = new URL(this.appDirectoryV3Url)
        iframeUrl.searchParams.append("darkmode", document.body.classList.contains("darkmode").toString())
        if (featureFlagIsActive("AppDirV3DashboardLayout")) {
            iframeUrl.searchParams.append("useDashboardLayout", "true")
        }

        this.frame.style.width = this.frameWidth
        this.frame.style.height = this.frameHeight
        this.frame.style.maxWidth = "100%"
        this.frame.style.boxSizing = "border-box"
        this.frame.style.borderStyle = "solid"
        this.frame.style.borderWidth = "1px"
        this.frame.style.borderRadius = "5px 5px 0 0"
        this.frame.style.borderColor = "var(--cbr-tooltip-border)"

        this.frame.allow = "clipboard-write; fullscreen"
        this.frame.src = iframeUrl.toString()
        this.frame.id = "app-directory-v3-iframe"
    }

    private createV3Toggle(): HTMLDivElement {
        const container = document.createElement("div")
        container.id = "app-directory-v3-toggle"
        const AppToggle = ReactComponentRegistry.get("AppDirToggle")
        const toggleValue = featureFlagIsActive("AppDirectoryV3OptIn") ? "legacy" : "v3"
        new AppToggle(
            {
                value: toggleValue,
                onChange: (value: string) => {
                    if(value !== toggleValue) {
                        if(value === "legacy") {
                            this.showConfirmationPopup()
                        } else if(value === "v3") {
                            this.switchAppDirectoryVersion("v3")
                        }
                    }
                },
            },
            container,
        )
        return container
    }

    private switchAppDirectoryVersion(mode: "v3" | "legacy"): void {
        postCb("/api/public/asp/toggle-v3-appdir/", { mode })
            .then(() => {
                window.location.reload()  
            })
    }

    private hideConfirmationPopup(): void {
        if (this.confirmationModal) {
            this.confirmationModal.dispose()
        }
    }

    private showConfirmationPopup(): void {
        const AppDirConfirmationModal = ReactComponentRegistry.get("AppDirConfirmationModal")
        if (this.container) {
            const container = document.createElement("div")
            container.id = "app-directory-v3-toggle2"
            this.confirmationModal = new AppDirConfirmationModal({
                isOpen: true,
                onClose: () => this.hideConfirmationPopup(),
                onSave: () => {
                    this.switchAppDirectoryVersion("legacy")
                },
            }, container)
            this.element.appendChild(container)
        }
    }

    private createV3OnboardingPopup(): HTMLDivElement {
        const container = document.createElement("div")
        container.id = APP_DIRECTORY_V3_ONBOARDING_KEY
        const V3OnboardingModal = ReactComponentRegistry.get("V3OnboardingModal")
        this.onboardingModal = new V3OnboardingModal(
            {
                isOpen: true,
                onClose: () => this.hideOnboardingPopup(),
                onSave: () => {
                    this.hideOnboardingPopup()
                    localStorage.setItem(APP_DIRECTORY_V3_ONBOARDING_KEY, "true")
                },
                slides: [
                    {
                        title: t`Introducing our new App Directory`,
                        description: t`Keep track of your running apps from the sidebar while discovering and configuring new ones in the main directory—all in a seamless, streamlined experience.`,
                        imgUrl: `${STATIC_URL_ROOT}images/asp/onboarding-slide1.png`,
                    },
                    {
                        title: t`Explore and manage apps in one place`,
                        description: t`We’ve launched a new design to make managing your apps easier and more intuitive. Now, you can quickly add, modify, and discover new apps—all in a seamless experience. Click “Next” to start a tutorial and learn more.`,
                        imgUrl: `${STATIC_URL_ROOT}images/asp/onboarding-slide2.png`,
                    },
                    {
                        title: t`Quickly switch apps and update settings on the go`,
                        description: t`Easily manage running apps, update settings on the go, and seamlessly return to your room to test changes—no need to switch between browser pages or deal with complicated navigation.`,
                        imgUrl: `${STATIC_URL_ROOT}images/asp/onboarding-slide3.png`,
                    },
                    {
                        title: t`Discover more powerful features`,
                        description: t`Discover additional features like favorites, tags, and filters—all designed to enhance your app experience.`,
                        imgUrl: `${STATIC_URL_ROOT}images/asp/onboarding-slide4.png`,
                    },
                ],
            },
            container,
        )
        return container
    }

    private hideOnboardingPopup(): void {
        if (this.onboardingModal) {
            this.onboardingModal.dispose()
        }
    }

    private createContentTitle(): HTMLParagraphElement {
        const contentTitle = document.createElement("p")
        contentTitle.style.fontFamily = "UbuntuBold"
        contentTitle.style.fontWeight = "bold"
        contentTitle.style.fontSize = "16px"
        contentTitle.style.fontStyle = "normal"
        contentTitle.textContent = t`App Directory`
        return contentTitle
    }
}
